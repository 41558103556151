import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'LUDO',
    lpAddresses: {
      97: '',
      56: '0x9da5387b3a7057767F9092826fA6914aA669Aa6d', // AUTO-BNB LP
    },
    tokenSymbol: 'LUDO',
    tokenAddresses: {
      97: '',
      56: '0xB2ba8338e8512b22c7dfa8Dedf770Bd684516137',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    token1: 'LUDO',
  },

  {
    pid: 1,
    risk: 3,
    // isTokenOnly: true,
    lpSymbol: 'LUDO-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x9da5387b3a7057767F9092826fA6914aA669Aa6d', // AUTO-BNB LP
    },
    tokenSymbol: 'LUDO',
    tokenAddresses: {
      97: '',
      56: '0xB2ba8338e8512b22c7dfa8Dedf770Bd684516137',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    token1: 'LUDO',
    token2: 'BNB',
  },

  {
    pid: 2,
    risk: 3,
    // isTokenOnly: true,
    lpSymbol: 'LUDO-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x5B0A33cEE08c95Dd5A5892D8b4f6DA9685594A77', // AUTO-BNB LP
    },
    tokenSymbol: 'LUDO',
    tokenAddresses: {
      97: '',
      56: '0xB2ba8338e8512b22c7dfa8Dedf770Bd684516137',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    token1: 'LUDO',
    token2: 'BUSD',
  },

  {
    pid: 3,
    risk: 3,
    // isTokenOnly: true,
    lpSymbol: 'BNB-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16', // AUTO-BNB LP
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      56: contracts.wbnb[56],
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    token1: 'BNB',
    token2: 'BUSD',
  },

  {
    pid: 4,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'BNB',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16', // AUTO-BNB LP
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      56: contracts.wbnb[56],
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    token1: 'BUSD',
  },

  {
    pid: 5,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'BUSD',
    lpAddresses: {
      97: '',
      56: '0xe9e7cea3dedca5984780bafc599bd69add087d56', // AUTO-BNB LP
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '',
      56: contracts.busd[56],
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    token1: 'BUSD',
  },
]

export default farms

// import contracts from './contracts'
// import { FarmConfig, QuoteToken } from './types'

// const farms: FarmConfig[] = [
//   {
//     pid: 0,
//     risk: 5,
//     isTokenOnly: true,
//     lpSymbol: 'LUDO',
//     lpAddresses: {
//       97: '0xC269204a6a159FbC1C6a24E82473D2C96D52Bdf3',
//       56: '0xa9264b9023D28A78fCF54fAc3CEc827CBbB6ceC0', // AUTO-BNB LP
//     },
//     tokenSymbol: 'LUDO',
//     tokenAddresses: {
//       97: '0xC269204a6a159FbC1C6a24E82473D2C96D52Bdf3',
//       56: '0xa9264b9023D28A78fCF54fAc3CEc827CBbB6ceC0',
//     },
//     quoteTokenSymbol: QuoteToken.BNB,
//     quoteTokenAdresses: contracts.wbnb,
//   },
//   {
//     pid: 1,
//     risk: 5,
//     lpSymbol: 'LUDO-BNB',
//     lpAddresses: {
//       97: '0xfd377f7cda76a3521b39e45a13ef904258863d48',
//       56: '0xa9264b9023D28A78fCF54fAc3CEc827CBbB6ceC0', // AUTO-BNB LP
//     },
//     tokenSymbol: 'LUDo',
//     tokenAddresses: {
//       97: '0xC269204a6a159FbC1C6a24E82473D2C96D52Bdf3',
//       56: '0xa9264b9023D28A78fCF54fAc3CEc827CBbB6ceC0',
//     },
//     quoteTokenSymbol: QuoteToken.BNB,
//     quoteTokenAdresses: contracts.wbnb,
//   },
//   // {
//   //   pid: 1,
//   //   risk: 5,
//   //   isTokenOnly: false,
//   //   lpSymbol: 'TOKEN-BUSD',
//   //   lpAddresses: {
//   //     97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
//   //     56: '0x0b54E1D5aD36569d4Da613043A2ae23EBf88Ceb1', // AUTO-BNB LP
//   //   },
//   //   tokenSymbol: 'TOKEN',
//   //   tokenAddresses: {
//   //     97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
//   //     56: '0xa9264b9023D28A78fCF54fAc3CEc827CBbB6ceC0',
//   //   },
//   //   quoteTokenSymbol: QuoteToken.BNB,
//   //   quoteTokenAdresses: contracts.wbnb,
//   // },
//   // {
//   //   pid: 2,
//   //   risk: 5,
//   //   isTokenOnly: false,
//   //   lpSymbol: 'TOKEN-BNB',
//   //   lpAddresses: {
//   //     97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
//   //     56: '0xC85f610DfacBF32d7173c4E53a56426F8313e479', // AUTO-BNB LP
//   //   },
//   //   tokenSymbol: 'TOKEN',
//   //   tokenAddresses: {
//   //     97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
//   //     56: '0xa9264b9023D28A78fCF54fAc3CEc827CBbB6ceC0',
//   //   },
//   //   quoteTokenSymbol: QuoteToken.BNB,
//   //   quoteTokenAdresses: contracts.wbnb,
//   // },
//   // {
//   //   pid: 3,
//   //   risk: 5,
//   //   isTokenOnly: false,
//   //   lpSymbol: 'TOKEN-USDT',
//   //   lpAddresses: {
//   //     97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
//   //     56: '0x2e57946720ba9e891854A7BB3E767bf8008559A8', // AUTO-BNB LP
//   //   },
//   //   tokenSymbol: 'TOKEN',
//   //   tokenAddresses: {
//   //     97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
//   //     56: '0xa9264b9023D28A78fCF54fAc3CEc827CBbB6ceC0',
//   //   },
//   //   quoteTokenSymbol: QuoteToken.BNB,
//   //   quoteTokenAdresses: contracts.wbnb,
//   // },
//   // {
//   //   pid: 4,
//   //   risk: 5,
//   //   isTokenOnly: false,
//   //   lpSymbol: 'BUSD-USDT',
//   //   lpAddresses: {
//   //     97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
//   //     56: '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00', // AUTO-BNB LP
//   //   },
//   //   tokenSymbol: 'BUSD',
//   //   tokenAddresses: {
//   //     97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
//   //     56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
//   //   },
//   //   quoteTokenSymbol: QuoteToken.BNB,
//   //   quoteTokenAdresses: contracts.wbnb,
//   // },
//   // {
//   //   pid: 5,
//   //   risk: 5,
//   //   isTokenOnly: false,
//   //   lpSymbol: 'BUSD-BNB',
//   //   lpAddresses: {
//   //     97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
//   //     56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16', // AUTO-BNB LP
//   //   },
//   //   tokenSymbol: 'BUSD',
//   //   tokenAddresses: {
//   //     97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
//   //     56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
//   //   },
//   //   quoteTokenSymbol: QuoteToken.BNB,
//   //   quoteTokenAdresses: contracts.wbnb,
//   // },
// ]

// export default farms
