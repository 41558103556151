export default {
  cake: {
    56: '0xB2ba8338e8512b22c7dfa8Dedf770Bd684516137',
    97: '0xC269204a6a159FbC1C6a24E82473D2C96D52Bdf3',
  },
  masterChef: {
    56: '0x36cACE3e213DD824FBea6AD0883Ab11D98DBBCC1',
    97: '0x5219A83330BFE46aA42039D9B82e5756a2EB7C1a',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0x337610d27c682e347c9cd60bd4b3b107c9d34ddd',
  },
  referral: {
    56: '',
    97: '0xF608ed7aD9e8740Bf16a31aBEe8C379C82823dC7',
  },

  presale: {
    56: '',
    97: '0x24Cb24347253EbD2C59701658eDeD2e4cc78be59',
  },
  dicegame: {
    56: '',
    97: '0x35902d53A08992F82Fe9351ae366FBBF99ddb3A6',
  },
  privatesale: {
    56: '0xdB866db238a5CFFd07EAfcc700C1d935f74D23Bb',
    97: '0x7343A2d05f7a55A449Fa66CB8A3bD8Fff24Cc72A',
  },
}
