import { BaseLayout } from '@pancakeswap-libs/uikit'
import Container from 'components/layout/Container'
import React from 'react'
import styled from 'styled-components'


const Cards = styled(BaseLayout)`
  align-items: stretch;
  justify-content: stretch;
  margin-bottom: 48px;

  & > div {
    grid-column: span 6;
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    & > div {
      grid-column: span 8;
    }
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    & > div {
      grid-column: span 4;
    }
  }
`

const Card = styled.div`
width: 60rem;
height: auto;
border: 6px solid #b0e1f2;
border-radius: 16px;
display: flex;
flex-direction: column;
align-items: center;
height: auto;
justify-content: space-between;
text-align: center;
padding: 1rem;
background-color: #353895;
margin-top: 4rem;
@media only screen and (max-width: 600px){
    width: 90%;
}
animation-name: borderanimate;
animation-duration: 1s;
animation-iteration-count:infinite;
@keyframes borderanimate{
    0%{
        border-image:linear-gradient(#009a2a,#00abff,#ffce31,#e42827) 1 round;
        border-image-width:12px;
        border-radius: 16px;
    }
    25%{
        border-image:linear-gradient(#e42827,#009a2a,#00abff,#ffce31) 1 round;
        border-image-width:12px;
        border-radius: 16px;

    }
    50%{
        border-image:linear-gradient(#ffce31,#e42827,#009a2a,#00abff) 1 round;
        border-image-width:12px;
        border-radius: 16px;

    }
    75%{
        border-image:linear-gradient(#00abff,#ffce31,#e42827,#009a2a) 1 round;
        border-image-width:12px;
        border-radius: 16px;
    }
    100%{
        border-image:linear-gradient(#009a2a,#00abff,#ffce31,#e42827) 1 round;
        border-image-width:12px;
        border-radius: 16px;
    }
}
`;




const Roadmap: React.FC = () => {
    return (
        <div style={{ marginTop: "50px", width: "100%", padding: "20px" }}>
            <Cards>
                <Card>
                    <ul style={{ color: "white", textAlign: "left", fontSize: "1.5rem" }}>
                        <li>
                            Launch of token
                        </li>
                        <li>
                            Marketing PUSH
                        </li>
                        <li>
                            Audition
                        </li>
                        <li>
                            Launch of LUDO Play game
                        </li>
                    </ul>
                </Card>
                <Card>
                    <ul style={{ color: "white", textAlign: "left", fontSize: "1.5rem" }}>
                        <li>
                            Launch of LUDO mobile application
                        </li>
                        <li>
                            Launch of NFT marketplace
                        </li>
                        <li>
                            Heavy Marketing
                        </li>
                        <li>
                            LUDO Swap
                        </li>
                    </ul>
                </Card>
                <Card>
                    <ul style={{ color: "white", textAlign: "left", fontSize: "1.5rem" }}>
                        <li>
                            Dex
                        </li>
                        <li>
                            Coin LaunchPad
                        </li>
                        <li>
                            Idea Realization
                        </li>
                        <li>
                            Token Created
                        </li>
                        <li>
                            Website Created
                        </li>
                    </ul>
                </Card>
            </Cards>
        </div>
    )
}

export default Roadmap
