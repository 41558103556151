import styled from 'styled-components'

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
<<<<<<< HEAD
  max-width: 100%;
  padding-left: 0px;
  padding-right: 0px;
=======
  max-width: 1092px;
  // padding-left: 16px;
  // padding-right: 16px;
>>>>>>> dd366c606b2f4e58f88de571e576fd9f2f10817c

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-left: 24px;
    padding-right: 24px;
    max-width: 95%;
  }
`

export default Container
