import Container from 'components/layout/Container'
import React from 'react'

const CommingSoon: React.FC = () => {
    return (
        <Container>
            <img src="https://i.pinimg.com/originals/5c/5d/66/5c5d6684644136c4b1442f1db30af6bf.gif" alt="gif" style={{margin:"auto", display:"block", marginTop:"50px"}} />
        </Container>
    )
}

export default CommingSoon
