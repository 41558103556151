import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap-libs/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
* {
  font-family: 'Montserrat', sans-serif;
  @font-face {
    font-family: myFirstFont;
    src: url('/images/noto.ttf');
        // url('/images/Teko-Light.ttf');
  }
  
  
    font-family: myFirstFont;
  
}
  body {
    background: ${({ theme }) => theme.colors.background}; 
    ${({ theme }) => theme.mediaQueries.sm}{
      background-size: 100vw 100vh;
    }
    background-size: auto 100vh;
    background-attachment: fixed;
    background-repeat: no-repeat;                               

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
