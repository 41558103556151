import { BaseLayout, Heading, Text } from '@pancakeswap-libs/uikit'
import Container from 'components/layout/Container'
import React from 'react'
import styled from 'styled-components'


const Cards = styled(BaseLayout)`
  align-items: stretch;
  justify-content: stretch;
  margin-bottom: 48px;

  & > div {
    grid-column: span 6;
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    & > div {
      grid-column: span 8;
    }
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    & > div {
      grid-column: span 4;
    }
  }
`

const Card = styled.div`
    // width: 60rem;
    height: auto;
    // border: 6px solid #FFF;
    border-image:linear-gradient(#009a2a,#00abff,#ffce31,#e42827) 1 round;
    border-image-width:12px;
    border-radius: 16px;
    text-align:left;
    display: flex;
    flex-direction: column;
    color:#fff;
    align-items: center;
    height: auto;
    justify-content: space-between;
    text-align: center;
    padding: 2rem;
    margin-top: 6rem;
    // background:#353895;
    animation-name: borderanimate;
  animation-duration: 1s;
  animation-iteration-count:infinite;
    @media only screen and (max-width: 600px){
        width: 100%;
        margin-top: 1rem;
    }

    @keyframes borderanimate{
        0%{
            border-image:linear-gradient(#009a2a,#00abff,#ffce31,#e42827) 1 round;
            border-image-width:12px;
        }
        25%{
            border-image:linear-gradient(#e42827,#009a2a,#00abff,#ffce31) 1 round;
            border-image-width:12px;

        }
        50%{
            border-image:linear-gradient(#ffce31,#e42827,#009a2a,#00abff) 1 round;
            border-image-width:12px;

        }
        75%{
            border-image:linear-gradient(#00abff,#ffce31,#e42827,#009a2a) 1 round;
            border-image-width:12px;

        }
        100%{
            border-image:linear-gradient(#009a2a,#00abff,#ffce31,#e42827) 1 round;
            border-image-width:12px;

        }
    }
`;

const Con = styled(Container)`
    margin: 2rem 0;
`

const Whitepaper: React.FC = () => {
    return (
        <Con >
            {/* <Card style={{ textAlign: "left" }}> */}
                <Text>
                    $LUDO was designed by experienced programmers, crypto investors and marketers that all have experience in the BSC DeFi space!
                </Text>
                <Text style={{ textAlign: "left" }}>
                    Read below to get a simple overview of the 7 protocols that compose our tokenomics.
                </Text>
                <Text>
                    Hyper-deflation
                    There are 3 ways tokens are burned in $LUDO. Together, they all aim to reduce the supply and increasing the value of your tokens.
                </Text>
                <Text>

                    Token Buyback
                    Everytime there is a sell, the contract will automatically purchase back tokens which will increase the price and buy pressure.
                </Text>
                <Text>

                    Autonomous Yield
                    With each transaction, every person holding $LUDO will receive interest automatically straight into their wallets.
                </Text>
                <Text>

                    Charity
                    Investing in $LUDO is investing in the world. A cut of each transaction is put towards a green future for us and this planet.
                </Text>
                <Text>

                    Auto-growing Liquidity
                    With each trade, the underlying liquidity automatically grows. This means a stronger token, and less volatility.
                </Text>
                <Text>
                    Marketing Outreach
                    A small cut of each transaction is used to go towards marketing the token and various business costs, keeping its name and brand alive.


                </Text>
                <div>
                    <Text>
                        Lucky PRIZE
                        The contract grows a portion of tokens over time. With each $LUDO dice throw, you have a chance to win 1/5 of the prize pool.
                    </Text>
                    <Text>
                        For detailed information, please read our technical whitepaper.

                    </Text>


                    <Heading>
                        HOW TO BUY LUDO TOKEN

                    </Heading>

                    <Heading>
                        How To Purchase $LUDO
                    </Heading>
                    <Text>
                        Buy on PancakeSwap V2
                        This will take approximately 15-30 minutes depending on how familar you are with cryptocurrencies. We recommend reading through the entire guide before starting.

                    </Text>
                    <Text>
                        Step 1) Setting up your wallet
                        Download Trust Wallet
                    </Text>

                    <Text>
                        We recommend Trust Wallet for beginners as it has built-in Binance Smart Chain support and a way to acquire BNB; however, MetaMask is another great alternative for Desktop users.
                        Setup Trust Wallet

                        Create a multi-coin wallet and make sure you write down and safely store your private key (also known as the seed phrase)!

                    </Text>


                    <Text>
                        Step 2) Purchasing BNB
                        Purchase BNB coins

                        Tap on &quot;Smart Chain&quot; on the main screen of Trust Wallet, then tap &quot;Buy&quot; in the top right. This step may require ID verification, so have documents ready to prove your identity.

                        Wait for the BNB to arrive

                        After your transaction has gone through, all you need to do is simply wait.

                        Since you are now transacting with a blockchain, you need to wait for your transactions to be mined. This can be a daunting for newcomers, but don&apos;t worry! Be patient, as this can take anywhere from a minute to an hour depending on network traffic.

                        Why did my transaction fail?

                        If you are in a country that doesn&apos;t support this method, or your bank has disabled international transfers, then you will need to acquire your BNB via one of the many other methods. Your next best bet is to buy your BNB on the Binance exchange and then transferring to your Trust Wallet. For a guide on how this is done, click here.

                    </Text>

                    <Text>
                        Step 3) Tracking $LUDO in your wallet
                        Tap the icon in the top-right and search for &quot;LUDO &quot;. If it&apos;s not there, tap &quot;Add Custom Token&quot;.

                        At the top, tap &quot;Ethereum&quot; next to Network, and change it to &quot;Smart Chain&quot;.

                        Copy 0x890dd3bfbbaf579c80e5fee7af7c29ff6318b439, and paste that in as the &quot;Contract Address&quot;.

                        Next, put LUDO Token as the &quot;Name&quot;, LUDO as the symbol, and 18 as the Decimals.

                        Finally click &quot;Save&quot; in the top right, and voila you now are tracking your wallets balance for $LUDO!

                    </Text>
                    <Text>


                        Step 4) Swapping BNB for $LUDO
                        Once you have a wallet, and your BNB has arrived, you are ready to purchase $LUDO .

                        Connecting your wallet to PancakeSwap

                        The first step is to connect your wallet to your phones browser. To do this click here: Buy on PancakeSwap V2

                        If you are prompted to import $LUDO , agree to the window and import it - this makes it easier for you to buy more in the future!

                        Click the &quot;Connect&quot; button in the top right, chose the Wallet Connect option and then select the Trust Wallet icon.

                        You will now be prompted to &quot;Connect&quot; to Trust Wallet, click the button and it will take you to your Trust Wallet to finish the process.

                        When that is done, you can return to your phones browser. You are now connected!

                        (If you still see the &quot;Connect&quot; button in the top right, and not your wallet address, then something went wrong. Close everything and restart this section.)

                        Setup the trade window

                        Click the  icon, and set the slippage to 8%. (If your transactions aren&apos;t clearing, try increase this in increments of 1% until they do)

                        Finally, set the amount of $LUDO you wish to purchase in the second input text field.

                        Buy $LUDO

                        All that there is left to do now, is click the Swap button.

                        After this transaction has cleared, you will have $LUDO in your wallet
                    </Text>

                    <Heading>
                        Tokenomics.
                    </Heading>
                    <Text>
                        XXX%

                        IDO

                        XXX%

                        Consultans

                        XXX%

                        Dev. & Marketing Funds

                        XXX%

                        Team Tokens

                        XXX%

                        Partnerships

                        XXX%

                        Liquidity

                        XXX%

                        Liquidity Mining Reserve


                        Initial circulating supply
                        1.000.000.000 LUDO


                        MARKETING XXX% of every transaction is allocated in marketing budget and contributes to fuel platform developments and aggressive promotions around all the blockchain space.

                        Reward XX% redistributed in BUSB
                        XXX% of every transaction is redistributed to all holders. Hold $LUDO tokens, earn USDT. A minimum of 200000 tokens is required in order to receive rewards


                    </Text>
                </div>
            {/* </Card> */}
        </Con>
    )
}

export default Whitepaper
