import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import useI18n from 'hooks/useI18n'
import { motion, useMotionValue, useTransform } from 'framer-motion'
import { usePrivateSale } from 'hooks/useContract';
import { getPrivateSaleAddress } from 'utils/addressHelpers';
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { Button, Card, CardBody, CardHeader, Heading, Input, Modal, Text } from '@pancakeswap-libs/uikit';
import Page from 'components/layout/Page';
import Divider from 'views/Pools/components/Divider';
import UnlockButton from 'components/UnlockButton';
import TokenInput from 'components/TokenInput';
import BigNumber from 'bignumber.js'



const HoverPage = styled.div`
padding: 1rem 2rem;

@-webkit-keyframes hvr-buzz {
    50% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg);
    }
    100% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg);
    }
  }
  @keyframes hvr-buzz {
    50% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg);
    }
    100% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg);
    }
  }
  
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  
    &:hover, &:focus, &:active {
    -webkit-animation-name: hvr-buzz;
    animation-name: hvr-buzz;
    -webkit-animation-duration: 0.15s;
    animation-duration: 0.15s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
  
  
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    // box-shadow: 0 0 1px blue;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
  
  &:hover, &:focus, &:active {
    box-shadow: 0 0 2rem  ${({ theme }) => theme.colors.secondary};
  }
  // cursor: grab;


  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4rem;
  font-weight: lighter !important;
  background: #ffce31;


`;

const Color = styled.div`
  color: #015914;
  display: flex;
  font-size: 2rem;
  font-weight: lighter !important;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.mediaQueries.sm}{
    font-size: 3.5rem ;
}
`

const PrivateSale = () => {
    const TranslateString = useI18n()
    const contract = usePrivateSale(getPrivateSaleAddress());
    const [owner, setowner] = useState("");
    const { account } = useWallet();
    const [value, setvalue] = useState("");
    const [loading, setloading] = useState(false);


    const [trans, settrans] = useState({transactionHash:""});
    const Owner = async () => {
        if (contract) {
            const ownerAddress = await contract.methods.owner().call();
            setowner(ownerAddress);
        }
    }

    useEffect(() => {
        if (contract) {
            Owner()
        }
        console.log(owner, "owner address");

    })

    const handleWithdraw = async () => {
        setloading(true);
        if (contract) {
            try {
                const tx = await contract.methods.withdrawAll().send({
                    from: account
                })
                if (tx.status) {
                    setloading(false);
                    settrans(tx)
                    // alert("SuccessFull")
                } else {
                    setloading(false);
                }
            } catch (err) {
                console.log(err);
                setloading(false)

            }

        }
    }

    const handleContribute = async () => {
        setloading(true);
        console.log("in handle");

        if (value && contract) {
            try {
                const tx = await contract.methods.contribute().send({
                    from: account,
                    value: new BigNumber(value).times(new BigNumber(10).pow(18)).toString()
                })
                if (tx.status) {
                    console.log(tx,"tx");
                    
                    setloading(false);
                    // alert("SuccesFull")
                    settrans(tx)
                    
                } else {
                    setloading(false);
                }
            } catch (err) {
                console.log(err);
                setloading(false)

            }
        }
        setloading(false);

    }

    const renderButton = (bottom) => {
        if (account) {
            if (loading) {
                <Button variant="primary">
                    Loading...
                </Button>
            }
            if (account === owner && bottom) {
                return (<Button onClick={() => { if (!loading) { handleWithdraw() } }} variant="primary">
                    {loading ? "Loading..." : "Withdraw"}
                </Button>);
            } return (
                <Button onClick={() => { if (!loading) { handleContribute() } }} variant="primary">
                    {loading ? "Loading..." : "Contribute"}
                </Button>)

        } return <UnlockButton />

    }

    useEffect(() => {
        console.log("hii");

    }, [loading])

    const handleChange = useCallback(
        (e: React.FormEvent<HTMLInputElement>) => {
            setvalue(e.currentTarget.value)
        },
        [setvalue],
    )


    const x = useMotionValue(0);
    const opacity = useTransform(x, [-200, 0, 200], [0.5, 1, 0.5]);
    return (
        // <HoverPage>
        //   <motion.h1 
        //    >
        //     <Color> ANNOUNCEMENT</Color>
        //   </motion.h1>

        //   {
        //       account === owner  && <Button variant="primary">
        //           Withdraw
        //       </Button>
        //   }
        // </HoverPage>
        <Page>
            <Card style={{ maxWidth: "400px", margin: "auto", marginTop: "100px" }}>
                <CardBody>
                    <Heading>
                        Private Sale
                    </Heading>
                    {/* <Divider /> */}
                    <hr />
                    <div style={{ marginTop: "10px", marginBottom: "10px", textAlign: "center" }}>
                        <Text color="#f00" fontSize='lg' bold>
                            Note: You can only contribute 2BNB
                        </Text>
                    </div>
                    <div>
                        <TokenInput
                            value={value}
                            // onSelectMax={handleSelectMax}
                            onChange={handleChange}
                            // max={2}
                            symbol="BNB"
                        // depositFeeBP={depositFeeBP}
                        />
                        {/* <Input onChange={(e) => setvalue(e.target.value)} style={{ padding: "15px" }} type="number" placeholder="Enter BNB to contribute" /> */}
                    </div>
                    <div style={{ marginTop: "20px", textAlign: "center" }}>
                        {renderButton(false)}
                    </div>
                </CardBody>
            </Card>
            <div style={{ margin: "auto", display: "block", textAlign: "center", marginTop: "20px" }} >
                {
                    account === owner && renderButton(true)
                }
            </div>

           {trans.transactionHash  &&  <Modal title="Transaction SuccessFull" onDismiss={() => settrans({transactionHash:""})} >
                <Card>
                    <CardBody>
                        <Heading>
                            Transaction SuccesFull
                        </Heading>
                        <Text bold color="#f00" href={`https://www.bscscan.com/tx/${trans?.transactionHash}`} as="a">
                            Check on BSCSCAN
                        </Text>
                    </CardBody>
                </Card>
            </Modal>}
        </Page>
    )
}

export default PrivateSale
