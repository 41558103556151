import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  // {
  //   label: 'Home',
  //   icon: 'HomeUpdatedIcon',
  //   href: '/',
  // },

  // {
  //   label: 'Trade',
  //   icon: 'TradeIcon',
  //   items: [
  //     {
  //       label: 'Exchange',
  //       href: 'https://pancakeswap.finance/swap?outputCurrency=0xB2ba8338e8512b22c7dfa8Dedf770Bd684516137', // 'https://exchange.goosedefi.com/',
  //     },
  //     {
  //       label: 'Liquidity',
  //       href: 'https://pancakeswap.finance/add/BNB/0xB2ba8338e8512b22c7dfa8Dedf770Bd684516137', //  'https://exchange.goosedefi.com/#/pool',
  //     },
  //   ],
  // },
  // {
  //   label: 'Farming',
  //   icon: 'FarmUpdatedIcon',
  //   href: '/farms',
  // },
  // {
  //   label: 'Staking',
  //   icon: 'PoolUpdatedIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Roadmap',
  //   icon: 'NftUpdatedIcon',
  //   href: '/road-map',
  // },
  // {
  //   label: 'Pre-sale',
  //   icon: 'PoolIcon',
  //   href: '/presale',
  // },
  // {
  //   label: 'Game',
  //   icon: 'NftIcon',
  //   href: '/game',
  // },
  {
    label: 'PrivateSale',
    icon: 'NftIcon',
    href: '/private-sale',
  },
  // {
  //   label: 'Gitbook',
  //   icon: 'MoreUpdatedIcon',
  //   href: '/lottery',
  // },
  // {
  //   label: 'Voting',
  //   icon: 'PoolUpdatedIcon',
  //   href: '/air',
  // },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Nft',
  //   icon: 'MoreUpdatedIcon',
  //   href: '/nft',
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'PancakeSwap',
  //       href: 'https://pancakeswap.info/token/0xF952Fc3ca7325Cc27D15885d37117676d25BfdA6',
  //     },
  //     {
  //       label: 'CoinGecko',
  //       href: 'https://www.coingecko.com/en/coins/goose-finance',
  //     },
  //     {
  //       label: 'CoinMarketCap',
  //       href: 'https://coinmarketcap.com/currencies/goose-finance/',
  //     },
  //     {
  //       label: 'AstroTools',
  //       href: 'https://app.astrotools.io/pancake-pair-explorer/0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
  //     },
  //   ],
  // },
  // {
  //   label: 'Price Charts',
  //   icon: 'MoreIcon',
  //   items: [
  //     {
  //       label: 'Github',
  //       href: '', // 'https://github.com/goosedefi/',
  //     },
  //     {
  //       label: 'Docs',
  //       href: '', // 'https://goosedefi.gitbook.io/goose-finance/',
  //     },
  //     {
  //       label: 'Blog',
  //       href: '', // 'https://goosefinance.medium.com/',
  //     },
  //   ],
  // },
  // {
  //   label: 'Partnerships/IFO',
  //   icon: 'GooseIcon',
  //   href: 'https://docs.google.com/forms/d/e/1FAIpQLSe7ycrw8Dq4C5Vjc9WNlRtTxEhFDB1Ny6jlAByZ2Y6qBo7SKg/viewform?usp=sf_link',
  // },
  // {
  //   label: 'Audit by Hacken',
  //   icon: 'AuditIcon',
  //   href: 'https://www.goosedefi.com/files/hackenAudit.pdf',
  // },
  // {
  //   label: 'Audit by CertiK',
  //   icon: 'AuditIcon',
  //   href: 'https://certik.org/projects/goose-finance',
  // },
]

export default config
